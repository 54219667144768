<template>
  <!-- Infomration here -->
  <v-data-table
    :headers="headers"
    :items="tests"
    @click:row="(row, slot) => slot.expand(!slot.isExpanded)"
    item-key="signalTestId"
    single-expand
    class="row-pointer"
    sort-by="active"
    sort-desc
  >
    <template v-slot:[`item.startTest`]="{ item }">
      {{ humanDate(item.startTest) }}
    </template>
    <template v-slot:[`item.endTest`]="{ item }">
      {{ humanDate(item.endTest) }}
    </template>

    <template v-slot:[`item.active`]="{ item }">
      <v-icon :color="item.active ? 'success' : 'error'">{{
        item.active ? "mdi-circle-slice-5" : "mdi-circle-slice-8"
      }}</v-icon>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <!-- Show buttons for ending test if the item is active -->
      <v-tooltip bottom v-if="item.active">
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            x-small
            color="success"
            v-on="on"
            @click.stop="stopTest(item.signalTestId)"
            style="margin-right: 1rem"
          >
            <v-icon>mdi-stop</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("signalTest.endTest") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            x-small
            color="accent"
            v-on="on"
            router
            :to="'/signaltest/peek/' + item.signalTestId"
          >
            <v-icon>mdi-eye</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("signalTest.peekTest") }}</span>
      </v-tooltip>
    </template>

    <template v-slot:expanded-item="{ item }">
      <td :colspan="headers.length + 1" class="px-0">
        <v-data-table
          class="overflow-datatable-scroll"
          :headers="[
            {
              text: $t('signalTest.fields.data.rssi'),
              value: 'rssi',
            },
            {
              text: $t('signalTest.fields.data.snr'),
              value: 'snr',
            },
            {
              text: $t('signalTest.fields.data.lat'),
              value: 'lat',
            },
            {
              text: $t('signalTest.fields.data.lng'),
              value: 'lng',
            },
            {
              text: $t('signalTest.fields.data.createdAt'),
              value: 'createdAt',
              dataType: 'Date',
            },
          ]"
          sort-by="createdAt"
          :items="item.datas"
          dense
          disable-pagination
          hide-default-footer
        >
          <template v-slot:[`item.createdAt`]="{ item }">
            {{ humanDate(item.createdAt, "yyyy-MM-dd HH:mm:ss") }}
          </template>
        </v-data-table>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "SignalTestList",
  data() {
    return {
      tests: [],
      headers: [
        { text: this.$t("signalTest.fields.testId"), value: "signalTestId" },
        {
          text: this.$t("signalTest.fields.description"),
          value: "description",
          dataType: "String",
        },
        {
          text: this.$t("signalTest.fields.startTest"),
          value: "startTest",
          dataType: "Date",
        },
        {
          text: this.$t("signalTest.fields.endTest"),
          value: "endTest",
          dataType: "Date",
        },
        {
          text: this.$t("signalTest.fields.active"),
          value: "active",
          dataType: "Boolean",
        },
        { text: "", value: "actions" },
      ],
    };
  },

  methods: {
    ...mapActions("signalTests", ["getTests", "endTest"]),

    async stopTest(testId) {
      await this.endTest(testId);
    },
  },

  async created() {
    this.tests = await this.getTests();
  },
};
</script>

<style>
.row-pointer > .v-data-table__wrapper > table > tbody > tr :hover {
  cursor: pointer;
}
.overflow-datatable-scroll {
  max-height: 300px;
  overflow-y: scroll;
}
</style>
