var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-data-table',{staticClass:"row-pointer",attrs:{"headers":_vm.headers,"items":_vm.tests,"item-key":"signalTestId","single-expand":"","sort-by":"active","sort-desc":""},on:{"click:row":(row, slot) => slot.expand(!slot.isExpanded)},scopedSlots:_vm._u([{key:`item.startTest`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.humanDate(item.startTest))+" ")]}},{key:`item.endTest`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.humanDate(item.endTest))+" ")]}},{key:`item.active`,fn:function({ item }){return [_c('v-icon',{attrs:{"color":item.active ? 'success' : 'error'}},[_vm._v(_vm._s(item.active ? "mdi-circle-slice-5" : "mdi-circle-slice-8"))])]}},{key:`item.actions`,fn:function({ item }){return [(item.active)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticStyle:{"margin-right":"1rem"},attrs:{"fab":"","x-small":"","color":"success"},on:{"click":function($event){$event.stopPropagation();return _vm.stopTest(item.signalTestId)}}},on),[_c('v-icon',[_vm._v("mdi-stop")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("signalTest.endTest")))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","color":"accent","router":"","to":'/signaltest/peek/' + item.signalTestId}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("signalTest.peekTest")))])])]}},{key:"expanded-item",fn:function({ item }){return [_c('td',{staticClass:"px-0",attrs:{"colspan":_vm.headers.length + 1}},[_c('v-data-table',{staticClass:"overflow-datatable-scroll",attrs:{"headers":[
          {
            text: _vm.$t('signalTest.fields.data.rssi'),
            value: 'rssi',
          },
          {
            text: _vm.$t('signalTest.fields.data.snr'),
            value: 'snr',
          },
          {
            text: _vm.$t('signalTest.fields.data.lat'),
            value: 'lat',
          },
          {
            text: _vm.$t('signalTest.fields.data.lng'),
            value: 'lng',
          },
          {
            text: _vm.$t('signalTest.fields.data.createdAt'),
            value: 'createdAt',
            dataType: 'Date',
          },
        ],"sort-by":"createdAt","items":item.datas,"dense":"","disable-pagination":"","hide-default-footer":""},scopedSlots:_vm._u([{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.humanDate(item.createdAt, "yyyy-MM-dd HH:mm:ss"))+" ")]}}],null,true)})],1)]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }