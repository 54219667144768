<template>
  <v-main align="center">
    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="10">
          <v-card>
            <v-toolbar flat>
              <v-toolbar-title>{{
                $t("signalTest.toolbarTitle")
              }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      class="mx-2"
                      small
                      color="primary"
                      v-on="on"
                      @click.stop="() => (createDialog = !createDialog)"
                    >
                      <!-- Open Dialog to create test -->
                      {{ $t("signalTest.startTest") }}
                    </v-btn>
                  </template>
                  <span>{{ $t("common.create") }}</span>
                </v-tooltip>
              </div>
            </v-toolbar>
            <v-card-text>
              <SignalTestList />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="createDialog" max-width="600">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>
            {{ $t("signalTest.createToolbar") }}
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <v-text-field
            :label="$t('signalTest.fields.description')"
            name="description"
            v-model="description"
            prepend-icon="mdi-text"
          />

          <v-autocomplete
            autocomplete="off"
            :label="$t('alarm.fields.tag')"
            v-model="deveui"
            :items="tags"
            item-text="name"
            item-value="deveui"
            prepend-icon="mdi-cellphone-link"
            clearable
          ></v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="primary lighten-1" text @click="createDialog = false">{{
            $t("common.disagree")
          }}</v-btn>

          <v-btn color="primary" text @click="handleSubmitTest">{{
            $t("common.accept")
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>



<script>
import { mapActions, mapState } from "vuex";
import SignalTestList from "@/components/signaltest/SignalTestList";
export default {
  name: "SignalTest",
  data() {
    return {
      createDialog: false,
      description: "",
      deveui: null,
    };
  },

  methods: {
    ...mapActions("signalTests", ["startTest"]),
    ...mapActions("tag", ["getTags"]),

    async handleSubmitTest() {
      await this.startTest({
        deveui: this.deveui,
        payload: { description: this.description },
      });

      if (Object.keys(this.currentTest).length > 0) {
        this.$router.push(`/signaltest/peek/${this.currentTest.signalTestId}`);
      }
    },
  },

  computed: {
    ...mapState("tag", { tags: "tags" }),
    ...mapState("signalTests", ["currentTest"]),
  },

  async created() {
    await this.getTags();
  },

  components: {
    SignalTestList,
  },
};
</script>

<style>
.row-pointer > .v-data-table__wrapper > table > tbody > tr :hover {
  cursor: pointer;
}
.overflow-datatable-scroll {
  max-height: 300px;
  overflow-y: scroll;
}
</style>
